<template lang="html">
  <v-container pa-0 fluid v-if="encounterId">
    <v-row dense>
      <v-col cols="12">
        <v-encounter-bar :value="encounterId" @encounterLoaded="onEncounterLoaded" @encounterUnloaded="onEncounterUnloaded"></v-encounter-bar>
      </v-col>
      <v-col cols="12" v-if="currentEncounter">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="dashboard">Dashboard</v-tab>
            <v-tab key="printDocuments">พิมพ์เอกสาร</v-tab>
            <v-tab key="scanDocuments">สแกนเอกสาร</v-tab>
            <v-tab key="medicalRecords">เวชระเบียน</v-tab>
            <v-tab key="radiology">รังสีวินิจฉัย</v-tab>
            <v-tab key="dispensings">จ่ายของ</v-tab>
            <v-tab key="dispensingsTemporary">จ่ายยา</v-tab>
            <v-tab key="temporaryStock">ตะกร้ายา</v-tab>
            <v-tab key="transactions">ค่าใช้จ่าย</v-tab>
            <v-tab key="vouchers" v-permission="'ipd-voucher'">Vouchers</v-tab>
            <v-tab key="appointments">นัดหมาย</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="dashboard">
              <v-nurse-ipd-dashboard :encounter="currentEncounter" @encounterUnloaded="reset"></v-nurse-ipd-dashboard>
            </v-tab-item>
            <v-tab-item key="printDocuments">
              <v-document-print-list :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :show-ipd="true"></v-document-print-list>
            </v-tab-item>
            <v-tab-item key="scanDocuments">
              <v-document-scan :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" refreshable></v-document-scan>
            </v-tab-item>
            <v-tab-item key="medicalRecords">
              <v-document-list :hn="currentEncounter.hn" folder="default"></v-document-list>
            </v-tab-item>
            <v-tab-item key="radiology">
              <v-radiology-list :hn="currentEncounter.hn"></v-radiology-list>
            </v-tab-item>
            <v-tab-item key="dispensings">
              <v-encounter-dispensings :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :transactions-table="$refs.transactionsTable"></v-encounter-dispensings>
            </v-tab-item>
            <v-tab-item key="dispensingsTemporary">
              <v-encounter-temporary-dispensings :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :transactions-table="$refs.transactionsTable"></v-encounter-temporary-dispensings>
            </v-tab-item>
            <v-tab-item key="temporaryStock">
              <v-encounter-temporary-stock :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId"></v-encounter-temporary-stock>
            </v-tab-item>
            <v-tab-item key="transactions">
              <v-encounter-transaction ref="transactionsTable" 
                :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId"
                :products-filter="{productType: ['procedure','service','laboratory','radiology','package']}"
                :read-only="!!currentEncounter.dischargeDateTime"
              ></v-encounter-transaction>
            </v-tab-item>
            <v-tab-item key="vouchers" v-permission="'ipd-voucher'">
              <v-encounter-voucher :encounterId="currentEncounter.encounterId"></v-encounter-voucher>
            </v-tab-item>
            <v-tab-item key="appointments">
              <v-appointment :hn="currentEncounter.hn" :encounterId="currentEncounter.encounterId" :clinicCode="currentEncounter.clinicCode" :doctorCode="currentEncounter.doctorCode"></v-appointment>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
  <v-container pa-0 fluid v-else>
    <v-row dense>
      <v-col cols="12">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="currentAdmission">Current admission</v-tab>
            <v-tab key="dischargeAdmission">Discharged admission</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="currentAdmission">
              <v-model-table model-name="Encounters" model-title="Current admission"
                :model-api="['models','Registration','Encounters']" model-key="encounterId"
                :model-api-search="encounterQuery" 
                :headers="headers"
                :insertable="false"
                force-reload
                >
                <template v-slot:item.admitDateTime="props">
                  <v-label-datetime :date-time="props.item.admitDateTime" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.locationSubunitCode="props">
                  <v-label-items :items="props.item.location.subunits" item-key="subunitCode" item-value="subunitName" :value="props.item.locationSubunitCode"></v-label-items>
                </template>
                <template v-slot:item.action="props">
                  <v-btn :to="{name:'ipd.nursecounter', params: {encounterId: props.item.encounterId}}" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>&nbsp;
                  <v-action-dialog v-permission="'ipd-encounter-discharge'" ActivateButtonText="Discharge" formTitle="Discharge detail" @saveData="props.actions.saveData" maxWidth="40vw" :object="props.item">
                    <template v-slot="{data,rules}">
                      <v-container>
                        <v-row>
                          <v-date-time-field :rules="[rules.require()]" v-model="data.dischargeDateTime" :default-date-time="true"></v-date-time-field>
                        </v-row>
                      </v-container>
                    </template>
                  </v-action-dialog>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item key="dischargeAdmission">
              <v-model-table model-name="Encounters" model-title="Discharged admission"
                :model-api="['models','Registration','Encounters']" model-key="encounterId"
                :model-api-search="dischargedQuery" 
                api-order-by="dischargeDateTime,DESC"
                :headers="dischargedHeaders"
                :insertable="false"
                server-pagination
                force-reload
                >
                <template v-slot:item.admitDateTime="props">
                  <v-label-datetime :date-time="props.item.admitDateTime" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.dischargeDateTime="props">
                  <v-label-datetime :date-time="props.item.dischargeDateTime" short-date-time></v-label-datetime>
                </template>
                <template v-slot:item.patient.name_th="props">
                  <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
                </template>
                <template v-slot:item.locationSubunitCode="props">
                  <v-label-items :items="props.item.location.subunits" item-key="subunitCode" item-value="subunitName" :value="props.item.locationSubunitCode"></v-label-items>
                </template>
                <template v-slot:item.action="props">
                  <v-btn :to="{name:'ipd.nursecounter', params: {encounterId: props.item.encounterId}}" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import encounterLoader from '@/components/mixins/encounterLoader'

export default {
  mixins: [encounterLoader],
  data: ()=>({
    headers: [
      {
        text: 'เวลา Admit',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'Encounter ID',
        value: 'encounterId',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'Ward',
        value: 'location.locationName',
        class: 'body-2',
      },
      {
        text: 'ห้อง',
        value: 'locationSubunitCode',
        class: 'body-2'
      },
      {
        text: 'ชนิดเตียง',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '300px',
        sortable: false,
        class: 'body-2'
      }
    ],
    dischargedHeaders: [
      {
        text: 'เวลา Admit',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'เวลา Discharge',
        value: 'dischargeDateTime',
        class: 'body-2'
      },
      {
        text: 'Encounter ID',
        value: 'encounterId',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'Ward',
        value: 'location.locationName',
        class: 'body-2',
      },
      {
        text: 'ห้อง',
        value: 'locationSubunitCode',
        class: 'body-2'
      },
      {
        text: 'ชนิดเตียง',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '250px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  computed: {
    encounterQuery() {
      let result = {
        encounterType: 'IMP',
        'whereNull#dischargeDateTime': null,
      }
      return result
    },
    dischargedQuery() {
      let result = {
        encounterType: 'IMP',
        'whereNotNull#dischargeDateTime': null,
      }
      return result
    },
  },
  methods: {
    onEncounterUnloaded() {
      this.$router.push({name: 'ipd.nursecounter'}).catch(()=>{});
    },
    reset() {
      this.currentEncounter = undefined
      this.onEncounterUnloaded()
    },
  },
}
</script>

<style lang="css" scoped>
</style>
